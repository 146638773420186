

export default {
  props: {
    to: {
      type: String,
      default: ''
    },
    targetType: {
      required: true,
      type: Number
    },
    customClass: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    targetOpenNewPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      type: 1
    }
  },
  mounted() {
    if (this.targetType === 90) {
      this.type = 2
    } else if (!this.targetType || this.targetType === 99) {
      this.type = 3
    } else {
      this.type = 1
    }
  },
  methods: {
    linkTo() {
      if (this.to !== 'javaScript:;') {
        window.open(this.to, '_blank')
      }
    }
  }
}

