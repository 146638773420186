import Vue from 'vue'
import { Message } from 'element-ui'

// 在Vue的原型上添加实例方法，以全局调用
Vue.prototype.$tips = (options) => {
  const currentMsg = {
    type: 'error'
  }
  if (typeof options === 'string') {
    currentMsg.content = options
  } else if (typeof options === 'object') {
    Object.assign(currentMsg, options)
  }
  if (currentMsg.content.indexOf('成功') !== -1) {
    currentMsg.type = 'success'
  }
  console.log(currentMsg)
  Message({
    message: currentMsg.content,
    type: currentMsg.type || 'error'
  })
}
Vue.prototype.$msgBox = Vue.prototype.$tips
