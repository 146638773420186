import { render, staticRenderFns } from "./header.vue?vue&type=template&id=168fd6a2&scoped=true"
import script from "./header.vue?vue&type=script&lang=js"
export * from "./header.vue?vue&type=script&lang=js"
import style0 from "./header.vue?vue&type=style&index=0&id=168fd6a2&prod&scoped=true&rel=stylesheet%2Fscss&lang=scss"
import style1 from "./header.vue?vue&type=style&index=1&id=168fd6a2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "168fd6a2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CustomLink: require('D:/gitlab/air_train_plan/corporate_training_platform/swsj-education-web/components/custom/link.vue').default})
