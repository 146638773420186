import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _248feffc = () => interopDefault(import('..\\pages\\account\\index.vue' /* webpackChunkName: "pages/account/index" */))
const _0349a9c6 = () => interopDefault(import('..\\pages\\agreement.vue' /* webpackChunkName: "pages/agreement" */))
const _b50048ce = () => interopDefault(import('..\\pages\\appCompany.vue' /* webpackChunkName: "pages/appCompany" */))
const _231d2fd8 = () => interopDefault(import('..\\pages\\certificate\\index.vue' /* webpackChunkName: "pages/certificate/index" */))
const _71a9ef86 = () => interopDefault(import('..\\pages\\community\\index.vue' /* webpackChunkName: "pages/community/index" */))
const _2fb17260 = () => interopDefault(import('..\\pages\\company.vue' /* webpackChunkName: "pages/company" */))
const _7039e5b4 = () => interopDefault(import('..\\pages\\development\\index.vue' /* webpackChunkName: "pages/development/index" */))
const _1dc95936 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _35bc772c = () => interopDefault(import('..\\pages\\forget.vue' /* webpackChunkName: "pages/forget" */))
const _c9942d34 = () => interopDefault(import('..\\pages\\home\\index.vue' /* webpackChunkName: "pages/home/index" */))
const _5a1ff7d1 = () => interopDefault(import('..\\pages\\knowledge\\index.vue' /* webpackChunkName: "pages/knowledge/index" */))
const _3800def1 = () => interopDefault(import('..\\pages\\lecturer\\index.vue' /* webpackChunkName: "pages/lecturer/index" */))
const _8a7a1308 = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages/login" */))
const _82d1a668 = () => interopDefault(import('..\\pages\\permission.vue' /* webpackChunkName: "pages/permission" */))
const _645ac0e0 = () => interopDefault(import('..\\pages\\register.vue' /* webpackChunkName: "pages/register" */))
const _a93250bc = () => interopDefault(import('..\\pages\\register\\index.vue' /* webpackChunkName: "pages/register/index" */))
const _ddf3207a = () => interopDefault(import('..\\pages\\tpl.vue' /* webpackChunkName: "pages/tpl" */))
const _35cc4960 = () => interopDefault(import('..\\pages\\watch\\index.vue' /* webpackChunkName: "pages/watch/index" */))
const _48e970e8 = () => interopDefault(import('..\\pages\\development\\estimate\\index.vue' /* webpackChunkName: "pages/development/estimate/index" */))
const _26c7ad11 = () => interopDefault(import('..\\pages\\development\\qualification\\index.vue' /* webpackChunkName: "pages/development/qualification/index" */))
const _0b3086f7 = () => interopDefault(import('..\\pages\\development\\study\\index.vue' /* webpackChunkName: "pages/development/study/index" */))
const _68567248 = () => interopDefault(import('..\\pages\\home\\rank.vue' /* webpackChunkName: "pages/home/rank" */))
const _ca18fb86 = () => interopDefault(import('..\\pages\\integral\\mall.vue' /* webpackChunkName: "pages/integral/mall" */))
const _368f25d7 = () => interopDefault(import('..\\pages\\investigate\\view.vue' /* webpackChunkName: "pages/investigate/view" */))
const _dbbe77fa = () => interopDefault(import('..\\pages\\watch\\catePanel.vue' /* webpackChunkName: "pages/watch/catePanel" */))
const _23687606 = () => interopDefault(import('..\\pages\\watch\\course.vue' /* webpackChunkName: "pages/watch/course" */))
const _7c3e0b9d = () => interopDefault(import('..\\pages\\watch\\practice.vue' /* webpackChunkName: "pages/watch/practice" */))
const _5621bfe7 = () => interopDefault(import('..\\pages\\watch\\task.vue' /* webpackChunkName: "pages/watch/task" */))
const _abd0f220 = () => interopDefault(import('..\\pages\\account\\exam\\detail.vue' /* webpackChunkName: "pages/account/exam/detail" */))
const _00deec84 = () => interopDefault(import('..\\pages\\account\\exam\\examQuestion.vue' /* webpackChunkName: "pages/account/exam/examQuestion" */))
const _a71ec156 = () => interopDefault(import('..\\pages\\account\\exam\\examSuccess.vue' /* webpackChunkName: "pages/account/exam/examSuccess" */))
const _13a9daf0 = () => interopDefault(import('..\\pages\\account\\exam\\record.vue' /* webpackChunkName: "pages/account/exam/record" */))
const _13961afa = () => interopDefault(import('..\\pages\\account\\plan\\detail.vue' /* webpackChunkName: "pages/account/plan/detail" */))
const _31f3d450 = () => interopDefault(import('..\\pages\\development\\estimate\\batchEvaluation.vue' /* webpackChunkName: "pages/development/estimate/batchEvaluation" */))
const _1b8a7bf2 = () => interopDefault(import('..\\pages\\development\\estimate\\evaluation.vue' /* webpackChunkName: "pages/development/estimate/evaluation" */))
const _41d8662c = () => interopDefault(import('..\\pages\\development\\estimate\\report.vue' /* webpackChunkName: "pages/development/estimate/report" */))
const _6ce43123 = () => interopDefault(import('..\\pages\\account\\plan\\task\\add.vue' /* webpackChunkName: "pages/account/plan/task/add" */))
const _313e6bbf = () => interopDefault(import('..\\pages\\account\\plan\\task\\detail.vue' /* webpackChunkName: "pages/account/plan/task/detail" */))
const _cca53834 = () => interopDefault(import('..\\pages\\knowledge\\offcourse\\components\\AssessAttachment.vue' /* webpackChunkName: "pages/knowledge/offcourse/components/AssessAttachment" */))
const _5229dfaa = () => interopDefault(import('..\\pages\\account\\exam\\_id.vue' /* webpackChunkName: "pages/account/exam/_id" */))
const _a1b774f6 = () => interopDefault(import('..\\pages\\account\\task\\_id.vue' /* webpackChunkName: "pages/account/task/_id" */))
const _2cc7480d = () => interopDefault(import('..\\pages\\integral\\goods\\_id.vue' /* webpackChunkName: "pages/integral/goods/_id" */))
const _39e625c0 = () => interopDefault(import('..\\pages\\investigate\\analyze\\_id.vue' /* webpackChunkName: "pages/investigate/analyze/_id" */))
const _3631b63e = () => interopDefault(import('..\\pages\\knowledge\\course\\_id.vue' /* webpackChunkName: "pages/knowledge/course/_id" */))
const _21d0017e = () => interopDefault(import('..\\pages\\knowledge\\offcourse\\_id.vue' /* webpackChunkName: "pages/knowledge/offcourse/_id" */))
const _66d57592 = () => interopDefault(import('..\\pages\\knowledge\\project\\_id.vue' /* webpackChunkName: "pages/knowledge/project/_id" */))
const _58866858 = () => interopDefault(import('..\\pages\\lecturer\\view\\_id.vue' /* webpackChunkName: "pages/lecturer/view/_id" */))
const _00c8d606 = () => interopDefault(import('..\\pages\\live\\watch\\_watch.vue' /* webpackChunkName: "pages/live/watch/_watch" */))
const _55f2680a = () => interopDefault(import('..\\pages\\redirect\\_path.vue' /* webpackChunkName: "pages/redirect/_path" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _248feffc,
    name: "account___zh"
  }, {
    path: "/agreement",
    component: _0349a9c6,
    name: "agreement___zh"
  }, {
    path: "/appCompany",
    component: _b50048ce,
    name: "appCompany___zh"
  }, {
    path: "/certificate",
    component: _231d2fd8,
    name: "certificate___zh"
  }, {
    path: "/community",
    component: _71a9ef86,
    name: "community___zh"
  }, {
    path: "/company",
    component: _2fb17260,
    name: "company___zh"
  }, {
    path: "/development",
    component: _7039e5b4,
    name: "development___zh"
  }, {
    path: "/en",
    component: _1dc95936,
    name: "index___en"
  }, {
    path: "/forget",
    component: _35bc772c,
    name: "forget___zh"
  }, {
    path: "/home",
    component: _c9942d34,
    name: "home___zh"
  }, {
    path: "/knowledge",
    component: _5a1ff7d1,
    name: "knowledge___zh"
  }, {
    path: "/lecturer",
    component: _3800def1,
    name: "lecturer___zh"
  }, {
    path: "/login",
    component: _8a7a1308,
    name: "login___zh"
  }, {
    path: "/permission",
    component: _82d1a668,
    name: "permission___zh"
  }, {
    path: "/register",
    component: _645ac0e0,
    children: [{
      path: "",
      component: _a93250bc,
      name: "register___zh"
    }]
  }, {
    path: "/tpl",
    component: _ddf3207a,
    name: "tpl___zh"
  }, {
    path: "/watch",
    component: _35cc4960,
    name: "watch___zh"
  }, {
    path: "/development/estimate",
    component: _48e970e8,
    name: "development-estimate___zh"
  }, {
    path: "/development/qualification",
    component: _26c7ad11,
    name: "development-qualification___zh"
  }, {
    path: "/development/study",
    component: _0b3086f7,
    name: "development-study___zh"
  }, {
    path: "/en/account",
    component: _248feffc,
    name: "account___en"
  }, {
    path: "/en/agreement",
    component: _0349a9c6,
    name: "agreement___en"
  }, {
    path: "/en/appCompany",
    component: _b50048ce,
    name: "appCompany___en"
  }, {
    path: "/en/certificate",
    component: _231d2fd8,
    name: "certificate___en"
  }, {
    path: "/en/community",
    component: _71a9ef86,
    name: "community___en"
  }, {
    path: "/en/company",
    component: _2fb17260,
    name: "company___en"
  }, {
    path: "/en/development",
    component: _7039e5b4,
    name: "development___en"
  }, {
    path: "/en/forget",
    component: _35bc772c,
    name: "forget___en"
  }, {
    path: "/en/home",
    component: _c9942d34,
    name: "home___en"
  }, {
    path: "/en/knowledge",
    component: _5a1ff7d1,
    name: "knowledge___en"
  }, {
    path: "/en/lecturer",
    component: _3800def1,
    name: "lecturer___en"
  }, {
    path: "/en/login",
    component: _8a7a1308,
    name: "login___en"
  }, {
    path: "/en/permission",
    component: _82d1a668,
    name: "permission___en"
  }, {
    path: "/en/register",
    component: _645ac0e0,
    children: [{
      path: "",
      component: _a93250bc,
      name: "register___en"
    }]
  }, {
    path: "/en/tpl",
    component: _ddf3207a,
    name: "tpl___en"
  }, {
    path: "/en/watch",
    component: _35cc4960,
    name: "watch___en"
  }, {
    path: "/home/rank",
    component: _68567248,
    name: "home-rank___zh"
  }, {
    path: "/integral/mall",
    component: _ca18fb86,
    name: "integral-mall___zh"
  }, {
    path: "/investigate/view",
    component: _368f25d7,
    name: "investigate-view___zh"
  }, {
    path: "/watch/catePanel",
    component: _dbbe77fa,
    name: "watch-catePanel___zh"
  }, {
    path: "/watch/course",
    component: _23687606,
    name: "watch-course___zh"
  }, {
    path: "/watch/practice",
    component: _7c3e0b9d,
    name: "watch-practice___zh"
  }, {
    path: "/watch/task",
    component: _5621bfe7,
    name: "watch-task___zh"
  }, {
    path: "/account/exam/detail",
    component: _abd0f220,
    name: "account-exam-detail___zh"
  }, {
    path: "/account/exam/examQuestion",
    component: _00deec84,
    name: "account-exam-examQuestion___zh"
  }, {
    path: "/account/exam/examSuccess",
    component: _a71ec156,
    name: "account-exam-examSuccess___zh"
  }, {
    path: "/account/exam/record",
    component: _13a9daf0,
    name: "account-exam-record___zh"
  }, {
    path: "/account/plan/detail",
    component: _13961afa,
    name: "account-plan-detail___zh"
  }, {
    path: "/development/estimate/batchEvaluation",
    component: _31f3d450,
    name: "development-estimate-batchEvaluation___zh"
  }, {
    path: "/development/estimate/evaluation",
    component: _1b8a7bf2,
    name: "development-estimate-evaluation___zh"
  }, {
    path: "/development/estimate/report",
    component: _41d8662c,
    name: "development-estimate-report___zh"
  }, {
    path: "/en/development/estimate",
    component: _48e970e8,
    name: "development-estimate___en"
  }, {
    path: "/en/development/qualification",
    component: _26c7ad11,
    name: "development-qualification___en"
  }, {
    path: "/en/development/study",
    component: _0b3086f7,
    name: "development-study___en"
  }, {
    path: "/en/home/rank",
    component: _68567248,
    name: "home-rank___en"
  }, {
    path: "/en/integral/mall",
    component: _ca18fb86,
    name: "integral-mall___en"
  }, {
    path: "/en/investigate/view",
    component: _368f25d7,
    name: "investigate-view___en"
  }, {
    path: "/en/watch/catePanel",
    component: _dbbe77fa,
    name: "watch-catePanel___en"
  }, {
    path: "/en/watch/course",
    component: _23687606,
    name: "watch-course___en"
  }, {
    path: "/en/watch/practice",
    component: _7c3e0b9d,
    name: "watch-practice___en"
  }, {
    path: "/en/watch/task",
    component: _5621bfe7,
    name: "watch-task___en"
  }, {
    path: "/account/plan/task/add",
    component: _6ce43123,
    name: "account-plan-task-add___zh"
  }, {
    path: "/account/plan/task/detail",
    component: _313e6bbf,
    name: "account-plan-task-detail___zh"
  }, {
    path: "/en/account/exam/detail",
    component: _abd0f220,
    name: "account-exam-detail___en"
  }, {
    path: "/en/account/exam/examQuestion",
    component: _00deec84,
    name: "account-exam-examQuestion___en"
  }, {
    path: "/en/account/exam/examSuccess",
    component: _a71ec156,
    name: "account-exam-examSuccess___en"
  }, {
    path: "/en/account/exam/record",
    component: _13a9daf0,
    name: "account-exam-record___en"
  }, {
    path: "/en/account/plan/detail",
    component: _13961afa,
    name: "account-plan-detail___en"
  }, {
    path: "/en/development/estimate/batchEvaluation",
    component: _31f3d450,
    name: "development-estimate-batchEvaluation___en"
  }, {
    path: "/en/development/estimate/evaluation",
    component: _1b8a7bf2,
    name: "development-estimate-evaluation___en"
  }, {
    path: "/en/development/estimate/report",
    component: _41d8662c,
    name: "development-estimate-report___en"
  }, {
    path: "/knowledge/offcourse/components/AssessAttachment",
    component: _cca53834,
    name: "knowledge-offcourse-components-AssessAttachment___zh"
  }, {
    path: "/en/account/plan/task/add",
    component: _6ce43123,
    name: "account-plan-task-add___en"
  }, {
    path: "/en/account/plan/task/detail",
    component: _313e6bbf,
    name: "account-plan-task-detail___en"
  }, {
    path: "/en/knowledge/offcourse/components/AssessAttachment",
    component: _cca53834,
    name: "knowledge-offcourse-components-AssessAttachment___en"
  }, {
    path: "/",
    component: _1dc95936,
    name: "index___zh"
  }, {
    path: "/en/account/exam/:id?",
    component: _5229dfaa,
    name: "account-exam-id___en"
  }, {
    path: "/en/account/task/:id?",
    component: _a1b774f6,
    name: "account-task-id___en"
  }, {
    path: "/en/integral/goods/:id?",
    component: _2cc7480d,
    name: "integral-goods-id___en"
  }, {
    path: "/en/investigate/analyze/:id?",
    component: _39e625c0,
    name: "investigate-analyze-id___en"
  }, {
    path: "/en/knowledge/course/:id?",
    component: _3631b63e,
    name: "knowledge-course-id___en"
  }, {
    path: "/en/knowledge/offcourse/:id?",
    component: _21d0017e,
    name: "knowledge-offcourse-id___en"
  }, {
    path: "/en/knowledge/project/:id?",
    component: _66d57592,
    name: "knowledge-project-id___en"
  }, {
    path: "/en/lecturer/view/:id?",
    component: _58866858,
    name: "lecturer-view-id___en"
  }, {
    path: "/en/live/watch/:watch?",
    component: _00c8d606,
    name: "live-watch-watch___en"
  }, {
    path: "/account/exam/:id?",
    component: _5229dfaa,
    name: "account-exam-id___zh"
  }, {
    path: "/account/task/:id?",
    component: _a1b774f6,
    name: "account-task-id___zh"
  }, {
    path: "/en/redirect/:path?",
    component: _55f2680a,
    name: "redirect-path___en"
  }, {
    path: "/integral/goods/:id?",
    component: _2cc7480d,
    name: "integral-goods-id___zh"
  }, {
    path: "/investigate/analyze/:id?",
    component: _39e625c0,
    name: "investigate-analyze-id___zh"
  }, {
    path: "/knowledge/course/:id?",
    component: _3631b63e,
    name: "knowledge-course-id___zh"
  }, {
    path: "/knowledge/offcourse/:id?",
    component: _21d0017e,
    name: "knowledge-offcourse-id___zh"
  }, {
    path: "/knowledge/project/:id?",
    component: _66d57592,
    name: "knowledge-project-id___zh"
  }, {
    path: "/lecturer/view/:id?",
    component: _58866858,
    name: "lecturer-view-id___zh"
  }, {
    path: "/live/watch/:watch?",
    component: _00c8d606,
    name: "live-watch-watch___zh"
  }, {
    path: "/redirect/:path?",
    component: _55f2680a,
    name: "redirect-path___zh"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
