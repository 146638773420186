import http from './request.js'
// 课程分类列表接口
export const getClassList = (params = {}) => {
  return http().post('/system/auth/category/list', params)
}

// 讲师课程分页
export const lecturerCourseList = (params = {}) => {
  return http().post('/course/auth/course/info/lecturer/page', params)
}
// 讲师线下课
export function lecturerOfflineList(data) {
  return http().post('/course/auth/offline/course/lecturer/page', data)
}

// 讲师直播课程信息汇总统计
export const lecturerLiveCourseCount = (params = {}) => {
  return http().post('/course/auth/course/info/lecturer/count', params)
}

// 讲师线下课程信息汇总统计
export const lecturerOfflineCourseCount = (params = {}) => {
  return http().post('/course/auth/offline/course/lecturer/count', params)
}

// 讲师线下课信息-详情
export const offlineCourseView = (courseId) => {
  return http().get('/course/auth/offline/course/view?courseId=' + courseId)
}

// 讲师线下课信息-上传附件查看
export const offlineCourseAttachmentList = (courseId) => {
  return http().get('/course/auth/offline/course/event/attachment/list?courseId=' + courseId)
}

// 讲师线下课信息-上传附件
export const offlineCourseAttachmentSave = (data) => {
  return http().post('/course/auth/offline/course/event/attachment/save', data)
}

// 讲师线下课信息-用户进度分页
export const offlineCourseUserProgressPage = (data) => {
  return http().post('/course/auth/offline/course/user/progress/page', data)
}

// 讲师线下课信息-考核评分用户列表
export const offlineCourseAssessUserList = (data) => {
  return http().post('/course/auth/offline/course/assess/user/list', data)
}

// 讲师线下课信息-考核评分
export const offlineCourseAssessSave = (data) => {
  return http().post('/course/auth/offline/course/assess/save', data)
}

// 讲师线下课信息-考核评分查看
export const offlineCourseAssessDetailView = (data) => {
  return http().post('/course/auth/offline/course/assess/detail/view', data)
}

// 讲师线下课信息-评价用户列表
export const offlineCourseEvaluationUserList = (data) => {
  return http().post('/course/auth/offline/course/evaluation/user/list', data)
}

// 讲师线下课信息-评价评分
export const offlineCourseEvaluationSave = (data) => {
  return http().post('/course/auth/offline/course/evaluation/save', data)
}

// 讲师线下课信息-评价评分查看
export const offlineCourseEvaluationDetailView = (data) => {
  return http().post('/course/auth/offline/course/evaluation/detail/view', data)
}

// 资源库课程信息分页
export const courseList = (params = {}) => {
  return http().post('/course/auth/course/info/page', params)
}

// 课程信息查看
export const userCourseDetail = (params = {}) => {
  return http().get('/course/auth/course/info/view?courseId=' + params.courseId)
}

// 课程课时列出
export const chapterDetail = (params = {}) => {
  return http().get('/course/auth/course/chapter/period/list?courseId=' + params.courseId)
}

// 课程评论分页
export const courseCommentList = (params = {}) => {
  return http().post('/course/auth/user/course/comment/page', params)
}

// 课程评论保存
export const courseCommentSave = (params = {}) => {
  return http().post('/course/auth/user/course/comment/save', params)
}

// 课程点赞
export const courseAdmire = (params = {}) => {
  return http().put('/course/auth/course/info/admire', params)
}

// 删除课程评论
export const courseCommentDelete = (id) => {
  return http().delete('/course/auth/user/course/comment/delete?id=' + id)
}

// 课程资源观看
export const resourceWatch = (params = {}) => {
  return new Promise(function(resolve, reject) {
    http().post('/course/auth/course/info/resource/watch', params).then(res => {
      let video = {}
      try {
        video = JSON.parse(res.videoConfig || '{}')
      } catch (e) {
        console.error(e)
      }
      resolve({
        ...res,
        ...video,
        playUrls: video
      })
    }).catch(err => {
      reject(err)
    })
  })
}

// 课程用户进度分页
export const courseProgressList = (params = {}) => {
  return http().post('/data/auth/course/user/progress/course/page', params)
}

// 记录用户学习记录
export const userResourceLogSave = (params = {}) => {
  return http().put('/data/auth/course/user/resource/log/update', params)
}

// 用户学习任务基本信息分页
export const studyTaskList = (params = {}) => {
  return http().post('/course/auth/study/task/page', params)
}

// 用户学习任务汇总统计
export const studyTaskCount = (params = {}) => {
  return http().post('/course/auth/study/task/count', params)
}

// 用户学习任务详细信息
export const studyTaskView = (params = {}) => {
  return http().get('/course/auth/study/task/user/detail/view?taskId=' + params.taskId)
}

// 学习任务查看-包含阶段信息
export const CourseStudyTaskView = (params) => {
  return http().post('/course/auth/study/task/view/task', params)
}

// 获取任务学习资源信息
export const taskResourceWatch = (params = {}) => {
  return new Promise(function(resolve, reject) {
    http().post('/course/auth/study/task/progress/get/resource', params).then(res => {
      let video = {}
      try {
        video = JSON.parse(res.videoConfig || '{}')
      } catch (e) {
        console.error(e)
      }
      resolve({
        ...res,
        ...video,
        playUrls: video
      })
    }).catch(err => {
      reject(err)
    })
  })
}

// 保存资源学习进度
export const taskProgressSave = (params = {}) => {
  return http().post('/course/auth/study/task/progress/save', params)
}

// 学习项目申请报名
export const applyStudyProject = (params = {}) => {
  return http().post('/course/auth/study/project/apply', params)
}

// 用户学习项目汇总统计
export const studyProjectCount = (params = {}) => {
  return http().post('/course/auth/study/project/count', params)
}

// 用户学习项目基本信息分页
export const studyProjectList = (params = {}) => {
  return http().post('/course/auth/study/project/page', params)
}

// 用户学习项目详细信息
export const studyProjectView = (params = {}) => {
  return http().get('/course/auth/study/project/user/detail/view', { params })
}

// 用户学习项目评分
export const updateResourceGrade = (params = {}) => {
  return http().post('/course/auth/mark/record/save', params)
}

export const viewResourceGrade = (params = {}) => {
  return http().post('/course/auth/mark/record/view', params)
}

// 学习项目点赞
export const projectAdmire = (params = {}) => {
  return http().put('/course/auth/study/project/admire', params)
}

// 学习项目收藏
export const projectCollect = (params = {}) => {
  return http().put('/course/auth/user/study/task/collection/save/or/delete', params)
}

// 学习项目评论分页
export const projectCommentList = (params = {}) => {
  return http().post('/course/auth/user/study/task/comment/page', params)
}

// 学习项目评论保存
export const projectCommentSave = (params = {}) => {
  return http().post('/course/auth/user/study/task/comment/save', params)
}

// 学习项目评论删除
export const projectCommentDel = (id) => {
  return http().delete('/course/auth/user/study/task/comment/delete?id=' + id)
}

// 个人中心-任务档案分页(学习任务)
export const taskFileList = (obj) => {
  return http().post('/course/auth/user/study/task/relation/task/page', obj)
}

// 档案学习任务统计
export const taskFileCount = obj => {
  return http().post('/course/auth/user/study/task/relation/task/count', obj)
}

// 档案考试任务统计
export const examTaskPersonageCollect = obj => {
  return http().post('/exam/auth/user/exam/task/record/personage/collect', obj)
}

// 档案课程数据统计
export const courseStudyCollect = obj => {
  return http().post('/data/auth/course/user/progress/user/course/study/collect', obj)
}

// 个人中心-学习项目
export const learnProject = (obj) => {
  return http().post('/course/auth/user/study/task/relation/project/page', obj)
}

// 档案学习项目统计
export const learnProjectCount = obj => {
  return http().post('/course/auth/user/study/task/relation/project/count', obj)
}

// 个人中心-导出任务档案
export const exportTaskFile = (params = {}) => {
  return http().post('/course/auth/user/study/task/relation/task/export', params)
}

// 个人中心-导出学习项目
export const exportStudyProjectFile = (params = {}) => {
  return http().post('/course/auth/user/study/task/relation/project/export', params)
}

// 个人中心-导出学习档案
export const exportStudyFile = (params = {}) => {
  return http().post('/data/auth/course/user/progress/export', params)
}

export const exportCertification = (params = {}) => {
  return http().post('/course/auth/user/study/task/relation/post/certification/export', params)
}

// 个人中心-考试任务
export const taskExam = (params = {}) => {
  return http().post('/exam/auth/user/exam/task/record/exam/task/page', params)
}

// 个人中心-导出考试任务
export const exportTaskExam = (params = {}) => {
  return http().post('/exam/auth/user/exam/task/record/exam/task/export', params)
}

// 获取播放sign
export const chapterSign = (params = {}) => {
  return http().post('', params)
}

// 用户考试任务详情
export function examTaskRecordView(param) {
  return http().post('/exam/auth/user/exam/task/record/view', param)
}

// 用户开始考试
export function paperRecordStartExam(data) {
  return http().post('/exam/auth/user/paper/record/start/exam', data)
}

// 保存试题答案
export function paperRecordAnswerSave(data) {
  return http().post('/exam/auth/user/paper/record/answer/save', data)
}

// 考试完成
export function paperRecordFinishExam(data) {
  return http().post('/exam/auth/user/paper/record/finish/exam', data)
}

// 查看解析
export function paperRecordView(id) {
  return http().post('/exam/auth/user/paper/record/view?id=' + id)
}

// 考试完成结果查看
export function paperRecordFinishExamView(data) {
  return http().post('/exam/auth/user/paper/record/finish/examview', data)
}

// 获取学习资源信息-开始学习
export function getTaskResource(data) {
  return http().post('/course/auth/study/task/progress/get/resource', data)
}

// 用户学习项目基本信息分页
export const studyLecturerProjectList = (params = {}) => {
  return http().post('/course/auth/study/project/lecturer/page', params)
}

// 课程信息汇总统计
export const studyCourseCount = (data) => {
  return http().post('/course/auth/course/info/count', data)
}

// 图文课观看
export function courseInfoImageTextWatch(data) {
  return http().get('/course/auth/course/info/image/text/watch', { params: data })
}

// 用户任务学习进度记录分页
export function courseUserTaskProgressPage(data) {
  return http().post('/course/auth/user/study/task/training/progress/page', data)
}

// 获取观看地址
export function liveRoomWatchUrl(data) {
  return http().get('/course/api/live/room/watch/url?id=' + data)
}

// 用户考试记录分页
export function examPaperRecordPage(param = {}, page = 1, size = 10) {
  return http().post('/exam/auth/user/paper/record/page', { pageCurrent: page, pageSize: size, ...param })
}

// 查看作答记录
export function examRecordPage(param = {}, page = 1, size = 10) {
  return http().post('/exam/auth/user/paper/record/getPaperRecord', { pageCurrent: page, pageSize: size, ...param })
}

/**
 * 课程配置查看
 * @returns {*}
 */
export function courseAuthInfoConfig() {
  return http().get('/user/auth/enterprise/config/course/view')
}

// 学习任务配置查看
export function studyProjectConfig() {
  return http().get('/user/auth/enterprise/config/study/project/view')
}

// 学习任务配置查看
export function taskInfoConfig() {
  return http().get('/user/auth/enterprise/config/study/task/view')
}

/**
 * 用户学习进入校验
 * @param data
 * @returns {*}
 */
export function courseAutoCheckRecord(data) {
  return http().post('/course/auth/study/task/progress/check/resource', data)
}

export function singHangUp(data) {
  return http().post('/data/auth/attendance/sign/record/course/hang/up', data)
}

// 获取课件列表
export function getCoursewareList(courseId) {
  return http().get('/course/auth/course/info/courseware/list?courseId=' + courseId)
}

// 下载课件
export function downloadCourseware(courseId) {
  return http().get('/course/auth/course/info/courseware/download?coursewareId=' + courseId)
}
export function downloadlibrary(courseId) {
  return http().get('/course/auth/resource/library/download?id=' + courseId)
}

// 直播观看
export function courseLiveWatch(data) {
  return http().post('/course/auth/course/info/live/watch', data)
}

// 直播回放观看
export function courseLiveBackWatch(data) {
  // return http().post('/course/auth/course/info/play/back/watch', data)
  return new Promise(function(resolve, reject) {
    http().post('/course/auth/course/info/play/back/watch', data).then(res => {
      let video = {}
      try {
        video = JSON.parse(res.videoConfig || '{}')
      } catch (e) {
        console.error(e)
      }
      resolve({
        ...res,
        ...video,
        playUrls: video
      })
    }).catch(err => {
      reject(err)
    })
  })
}

// 直播间离开记录数据
export function courseLiveLeaveRecord(data) {
  return http().post('/course/auth/course/info/live/leave', data)
}

// 个人中心-培训计划-分页
export function trainingNeedsPage(data) {
  return http().post('/course/auth/training/needs/page', data)
}
// 个人中心-培训计划-查看
export function trainingNeedsView(id = '') {
  return http().get('/course/auth/training/needs/view?id=' + id)
}
// 个人中心-培训计划-编辑
export function trainingNeedsEdit(data) {
  return http().put('/course/auth/training/needs/edit', data)
}

// 个人中心-培训计划-执行明细
export function planStudyTaskView(id = '') {
  return http().get('/course/auth/study/task/training/view?trainingNeedsId=' + id)
}
// 个人中心-培训计划-执行明细-解除绑定
export function studyTaskTrainingUnbind(data) {
  return http().put('/course/auth/study/task/training/unbind', data)
}
// 个人中心-培训计划-执行明细-删除
export function planStudyTaskDelete(id = '') {
  return http().delete('/course/auth/study/task/training/delete?id=' + id)
}

// 任务-实操查看
export function practicalView(id) {
  return http().get('/course/auth/study/task/progress/practical/view?phaseContentId=' + id)
}
// 提交
export function practicalSave(data) {
  return http().post('/course/auth/study/task/progress/practical/answer/save', data)
}
// 考勤-签到
export function taskAtteSing(data) {
  return http().post('/course/auth/study/task/progress/attendance/sign', data)
}
// 发布执行明细
export function taskPublish(data) {
  return http().put('/course/auth/study/task/edit/publish/status', data)
}

export function operatePage(data) {
  return http().post('/course/auth/user/study/task/relation/attendance/page', data)
}

export function operateCount(data) {
  return http().post('/course/auth/user/study/task/relation/attendance/count', data)
}

export function operateQrCode(id) {
  return http().get('/course/auth/user/study/task/relation/attendance/qrcode?phaseContentId=' + id)
}

export function myEvaluateCount(data) {
  return http().post('/course/auth/offline/course/evaluate/count', data)
}

export function evaluateTraineesList(data) {
  return http().post('/course/auth/offline/course/evaluation/student/page', data)
}

export function studentList(data) {
  return http().post('/course/auth/offline/course/evaluation/user/list', data)
}

export function investigateData(data) {
  return http().post('/exam/auth/user/investigate/task/record/investigate/view', data)
}

export function lecturerList(data) {
  return http().post('/course/auth/offline/course/evaluation/lecturer/page', data)
}

export function evaluateCourse(data) {
  return http().post('/course/auth/offline/course/evaluation/course/page', data)
}

export function attachmentList(id) {
  return http().get('/course/auth/offline/course/content/attachment/list?courseId=' + id)
}

export function traineesEvaluateList(data) {
  return http().post('/course/auth/offline/course/student/evaluation/page', data)
}

export function lecturerEvaluateList(data) {
  return http().post('/course/auth/offline/course/lecturer/evaluation/page', data)
}

export function traineesEvaluateGradeList(data) {
  return http().post('/course/auth/offline/course/student/evaluation/detail/page', data)
}

export function taskPage(data) {
  return http().post('/course/auth/evaluation/task/page', data)
}

export function taskCount(data) {
  return http().post('/course/auth/evaluation/task/evaluation/status/count', data)
}

export function evaluationDetailList(id) {
  return http().get('/course/auth/offline/course/student/evaluation/detail/list?id=' + id)
}

export function certificationCount(data) {
  return http().post('/course/auth/user/study/task/relation/post/certification/count', data)
}

export function certificationPage(data) {
  return http().post('/course/auth/user/study/task/relation/post/certification/page', data)
}

export function contentDetail(data) {
  return http().post('/course/auth/study/task/progress/user/content/detail', data)
}

export function countList(data) {
  return http().post('/course/auth/study/task/phase/content/exam/user/count/list', data)
}

/**
 * 资源库查看
 * @param params
 */
export function resourceLibraryView(params) {
  return http().get('/course/manage/resource/library/view?id=' + params)
}
