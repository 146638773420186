import Vue from 'vue'
import Element from 'element-ui'
import ChildResource from '@/components/knowledge/childResource'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer.vue'
export default ({ app }) => {
  Vue.use(Element, {
    i18n: (key, value) => app.i18n.t(key, value)
  })
  Vue.component('ChildResource', ChildResource)
  Vue.component('ElImageViewer', ElImageViewer)
}
