import LRU from 'lru-cache'

/**
 * 阿拉伯数字转中文数字,
 * 如果传入数字时则最多处理到21位，超过21位js会自动将数字表示成科学计数法，导致精度丢失和处理出错
 * 传入数字字符串则没有限制
 * @param {number|string} digit
 */
export const toZhDigit = (digit) => {
  digit = typeof digit === 'number' ? String(digit) : digit
  const zh = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  const unit = ['千', '百', '十', '']
  const quot = ['万', '亿', '兆', '京', '垓', '秭', '穰', '沟', '涧', '正', '载', '极', '恒河沙', '阿僧祗', '那由他', '不可思议', '无量', '大数']

  let breakLen = Math.ceil(digit.length / 4)
  let notBreakSegment = digit.length % 4 || 4
  let segment
  const zeroFlag = []; const allZeroFlag = []
  let result = ''

  while (breakLen > 0) {
    if (!result) { // 第一次执行
      segment = digit.slice(0, notBreakSegment)
      const segmentLen = segment.length
      for (let i = 0; i < segmentLen; i++) {
        if (segment[i] !== 0) {
          if (zeroFlag.length > 0) {
            result += '零' + zh[segment[i]] + unit[4 - segmentLen + i]
            // 判断是否需要加上 quot 单位
            if (i === segmentLen - 1 && breakLen > 1) {
              result += quot[breakLen - 2]
            }
            zeroFlag.length = 0
          } else {
            result += zh[segment[i]] + unit[4 - segmentLen + i]
            if (i === segmentLen - 1 && breakLen > 1) {
              result += quot[breakLen - 2]
            }
          }
        } else {
          // 处理为 0 的情形
          if (segmentLen === 1) {
            result += zh[segment[i]]
            break
          }
          zeroFlag.push(segment[i])
          continue
        }
      }
    } else {
      segment = digit.slice(notBreakSegment, notBreakSegment + 4)
      notBreakSegment += 4

      for (let j = 0; j < segment.length; j++) {
        if (segment[j] !== 0) {
          if (zeroFlag.length > 0) {
            // 第一次执行zeroFlag长度不为0，说明上一个分区最后有0待处理
            if (j === 0) {
              result += quot[breakLen - 1] + zh[segment[j]] + unit[j]
            } else {
              result += '零' + zh[segment[j]] + unit[j]
            }
            zeroFlag.length = 0
          } else {
            result += zh[segment[j]] + unit[j]
          }
          // 判断是否需要加上 quot 单位
          if (j === segment.length - 1 && breakLen > 1) {
            result += quot[breakLen - 2]
          }
        } else {
          // 第一次执行如果zeroFlag长度不为0, 且上一划分不全为0
          if (j === 0 && zeroFlag.length > 0 && allZeroFlag.length === 0) {
            result += quot[breakLen - 1]
            zeroFlag.length = 0
            zeroFlag.push(segment[j])
          } else if (allZeroFlag.length > 0) {
            // 执行到最后
            if (breakLen === 1) {
              result += ''
            } else {
              zeroFlag.length = 0
            }
          } else {
            zeroFlag.push(segment[j])
          }

          if (j === segment.length - 1 && zeroFlag.length === 4 && breakLen !== 1) {
            // 如果执行到末尾
            if (breakLen === 1) {
              allZeroFlag.length = 0
              zeroFlag.length = 0
              result += quot[breakLen - 1]
            } else {
              allZeroFlag.push(segment[j])
            }
          }
          continue
        }
      }

      --breakLen
    }

    return result
  }
}

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

export const getServerCache = (name) => {
  const CACHED = new LRU()
  console.log(CACHED.has(name), CACHED.get(name))
  return false // JSON.parse(CACHED.get(name) || '')
}

// time 缓存时间
export const setServerCache = (name = '', data = {}, time = 10 * 60 * 1000) => {
  const CACHED = new LRU()
  return CACHED.set(name, '11111111', time)
}
/**
 * @param {number} time
 * @returns {string}
 */
export const formatDay = (time) => {
  const d = new Date(time).getTime()
  const now = Date.now()
  const diffValue = now - d
  let result = ''
  const minute = 1000 * 60
  const hour = minute * 60
  const day = hour * 24
  // const halfamonth = day * 15
  const month = day * 30
  const year = month * 12

  const _year = diffValue / year
  const _month = diffValue / month
  const _week = diffValue / (7 * day)
  const _day = diffValue / day
  const _hour = diffValue / hour
  const _min = diffValue / minute

  if (_year >= 1) result = Math.floor(_year) + '年前'
  else if (_month >= 1) result = Math.floor(_month) + '个月前'
  else if (_week >= 1) result = Math.floor(_week) + '周前'
  else if (_day >= 1) result = Math.floor(_day) + '天前'
  else if (_hour >= 1) result = Math.floor(_hour) + '小时前'
  else if (_min >= 1) result = Math.floor(_min) + '分钟前'
  else result = '刚刚'
  return result
}

/**
 * 获取时长
 * @param mss
 * @returns {string}
 */
export const formatDuring = (mss) => {
  if (!mss) return '00:00:00'
  const hours = parseInt(mss / (1000 * 60 * 60))
  const minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = parseInt((mss % (1000 * 60)) / 1000)
  return (
    (hours < 10 ? '0' + hours : hours) +
    ':' +
    (minutes < 10 ? '0' + minutes : minutes) +
    ':' +
    (seconds < 10 ? '0' + seconds : seconds)
  )
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

export function timeStamp(second_time) {
  let time = parseInt(second_time) + '秒'
  if (parseInt(second_time) > 60) {
    const second = parseInt(second_time) % 60
    let min = parseInt(second_time / 60)
    time = min + '分钟'

    if (min > 60) {
      min = parseInt(second_time / 60) % 60
      let hour = parseInt(parseInt(second_time / 60) / 60)
      time = hour + '小时'

      if (hour > 24) {
        hour = parseInt(parseInt(second_time / 60) / 60) % 24
        const day = parseInt(parseInt(parseInt(second_time / 60) / 60) / 24)
        time = day + '天'
        if (hour > 0) {
          time += hour + '小时'
        }
      }
      if (min > 0) {
        time += min + '分钟'
      }
      if (second > 0) {
        time += second + '秒'
      }
    }
  }

  return time
}
// 获取文件大小
export function getSize(limit) {
  let size = ''
  if (limit < 0.1 * 1024) { // 小于0.1KB，则转化成B
    size = limit.toFixed(2) + 'B'
  } else if (limit < 0.1 * 1024 * 1024) { // 小于0.1MB，则转化成KB
    size = (limit / 1024).toFixed(2) + 'KB'
  } else if (limit < 0.1 * 1024 * 1024 * 1024) { // 小于0.1GB，则转化成MB
    size = (limit / (1024 * 1024)).toFixed(2) + 'MB'
  } else { // 其他转化成GB
    size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
  }

  const sizeStr = size + '' // 转成字符串
  const index = sizeStr.indexOf('.') // 获取小数点处的索引
  const dou = sizeStr.substr(index + 1, 2) // 获取小数点后两位的值
  if (dou === '00') { // 判断后两位是否为00，如果是则删除00
    return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
  }
  return size
}

export function throttle(func, wait = 500) {
  var timeout, context, args
  var previous = 0

  var later = function() {
    previous = +new Date()
    timeout = null
    func.apply(context, args)
  }

  var throttled = function() {
    var now = +new Date()
    // 下次触发 func 剩余的时间
    var remaining = wait - (now - previous)
    context = this
    args = arguments
    // 如果没有剩余的时间了或者你改了系统时间
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      previous = now
      func.apply(context, args)
    } else if (!timeout) {
      timeout = setTimeout(later, remaining)
    }
  }
  return throttled
}

/**
 * 函数防抖
 */
let timer = null
export function debounce(fn, delay) {
  // 记录上一次的延时器

  const delayTime = delay || 200
  if (timer) {
    clearTimeout(timer)
    timer = setTimeout(fn, delayTime)
  } else {
    timer = setTimeout(fn, delayTime)
  }
}

export const recombinationList = (list, label = 'categoryName', childrenList = 'childrenList') => {
  if (!list || !list.length) {
    return list
  }
  list.forEach(item => {
    item.disabled = !item.governAuthority
    if (item.childrenList) {
      item.childrenList = recombinationList(item.childrenList, label, childrenList)
    }
  })
  return list
}
